import { useEffect, useState, FC } from "react";
import {
  AutoCenter,
  ResultPage,
  Image,
  Selector,
  Stepper,
  Switch,
  Space,
  Input,
  Grid,
} from "antd-mobile";
import { ClockCircleFill } from "antd-mobile-icons";
import {
  ChelemDTO,
  ChelemModel,
  dtoToModel,
  initialChelem,
  modelToDTO,
  Race,
  RaceResult,
  RACES,
  RaceTopChrono,
  sortChelemRaces,
} from "./mkart.types";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useNavbar } from "../use-navbar";

interface CourseProps {
  // course: Race;
  // stats: RaceTopChrono[];
  // handleChange: (r: Race) => void;
}

const formatDateLong = (d: Date | undefined) => {
  var options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  if (!d) {
    return "";
  }
  return d.toLocaleDateString("fr", options);
};

const stringToMilliseconds = (str: string) => {
  const [minutes, seconds, ms] = str.split(
    new RegExp("[:.]")
  ) as any as Array<number>;
  return Number(minutes) * 60 * 1000 + Number(seconds) * 1000 + Number(ms);
};
const millisecondsToString = (milliseconds: number) => {
  const minutes = `0${new Date(milliseconds).getMinutes()}`.slice(-2);
  const seconds = `0${new Date(milliseconds).getSeconds()}`.slice(-2);
  const ms = `00${new Date(milliseconds).getMilliseconds()}`.slice(-3);
  return `${minutes}:${seconds}.${ms}`;
};

const millisecondsToChronoObject = (milliseconds: number) => {
  const minutes = new Date(milliseconds).getMinutes();
  const seconds = new Date(milliseconds).getSeconds();
  const ms = new Date(milliseconds).getMilliseconds();

  return { mn: minutes, s: seconds, ms: ms };
};

const calcPoints = (r: RaceResult, joueur: "j1" | "j2"): number => {
  const points = [0, 10, 8, 6, 4, 3, 2, 1, 0];
  return points[r[joueur]];
};

const BestTime = ({ stats }: { stats: RaceTopChrono[] }) => {
  console.log({ stats });
  return (
    <div className="checker-blue-background">
      <ResultPage.Card
        style={{
          padding: 16,
          borderRadius: "16px",
          margin: 8,
        }}
        className="checker-blue-background-inner"
      >
        <Space
          direction="vertical"
          align="center"
          justify="stretch"
          style={{ width: "100%" }}
        >
          <div style={{ fontSize: "1.5rem", width: "100%" }}>
            <ClockCircleFill /> Best Time
          </div>
          <Grid columns={2} gap={12}>
            {stats.length > 0 ? (
              stats.map((s) => (
                <>
                  <Grid.Item
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {s.date ? formatDateLong(s.date) : "Developer Time"}
                  </Grid.Item>
                  <Grid.Item
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {millisecondsToString(s.chronoInMs)}
                  </Grid.Item>
                </>
              ))
            ) : (
              <> </>
            )}
          </Grid>
        </Space>
      </ResultPage.Card>
    </div>
  );
};
const formatDate = (d: Date | undefined) => {
  if (!d) {
    return "";
  }
  return d.toLocaleTimeString("fr");
};

export const Course: FC<CourseProps> = () => {
  let navigate = useNavigate();
  let { chelemId } = useParams<"chelemId">();
  let { courseName } = useParams<"courseName">();
  const navbar = useNavbar();

  useEffect(() => {
    const returnToHome = () => {
      navigate("/" + chelemId);
    };
    if (navbar) {
      navbar.setBackArrow(true);
      navbar.setOnBack(returnToHome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chelemId, courseName]);

  const [result, setResult] = useState<RaceResult>({
    j1: 0,
    j2: 0,
    stars: 0,
    bigGuyIsLast: false,
    chronoInMs: 0,
  });
  const [chelem, setChelem] = useState<ChelemModel>(initialChelem);
  const [stats, setStats] = useState<RaceTopChrono[]>([]);
  const [chrono, setChrono] = useState<{ mn: number; s: number; ms: number }>({
    mn: 0,
    s: 0,
    ms: 0,
  });

  const race = RACES.find((r) => r.name === courseName);

  useEffect(() => {
    async function fetchChelemData(chelemId: string) {
      let chelemResult: ChelemModel = initialChelem;
      if (chelemId !== "new") {
        const response = await axios.get<ChelemDTO>("/api/chelems/" + chelemId);
        chelemResult = dtoToModel(response.data);
      }

      const idx = RACES.findIndex((r) => r.name === courseName) + 1;
      const result = await axios.get("/api/chelems/stats/" + idx);
      const chronos: RaceTopChrono[] = result.data.map((c: any) => ({
        chronoInMs: c.chronoInMs,
        date: new Date(c.date),
      }));
      console.log("devTime", stringToMilliseconds(RACES[idx - 1].devTime));
      chronos.push({
        chronoInMs: stringToMilliseconds(RACES[idx - 1].devTime),
        date: null,
      });
      chronos.sort((a, b) => a.chronoInMs - b.chronoInMs);

      const course = chelemResult.courses?.find((c) => c.name === courseName);
      if (course) {
        setResult(course.result);
        setChelem(chelemResult);
        setStats(chronos);
        setChrono(millisecondsToChronoObject(course.result.chronoInMs));
      }
    }

    fetchChelemData(chelemId || "");
  }, [chelemId, courseName]);

  if (!race || !chelem) {
    return <></>;
  }

  const handleChange = async (course: Race) => {
    const newCourses = [
      ...chelem.courses.filter((c) => c.name !== course.name),
      course,
    ];
    const stat = newCourses.reduce(
      (acc, c) => {
        acc.ptsJ1 += calcPoints(c.result, "j1");
        acc.ptsJ2 += calcPoints(c.result, "j2");
        if (c.result.j1 && c.result.j2) {
          acc.nb++;
        }
        return acc;
      },
      { ptsJ1: 0, ptsJ2: 0, nb: 0 }
    );
    const newChelem = {
      ...chelem,
      courses: newCourses.sort(sortChelemRaces),
      ...stat,
    };
    console.log({ chelem });
    if (chelem?._id) {
      await axios.patch("/api/chelems/" + chelem._id, modelToDTO(newChelem));
      setChelem(newChelem);
      return newChelem;
    } else {
      const result = await axios.post("/api/chelems", modelToDTO(newChelem));
      newChelem._id = result.data._id;
      setChelem(newChelem);
      return newChelem;
    }
  };

  const handleSave = async () => {
    const course = chelem.courses?.find((c) => c.name === courseName);
    if (!course || result.j1 === 0 || result.j2 === 0) {
      return;
    }
    course.result = result;
    if (!course.result.date) {
      course.result.date = new Date();
    }
    course.result.chronoInMs =
      chrono.mn * 60 * 1000 + chrono.s * 1000 + chrono.ms;
    console.log("save course", { course });
    const newChelem = await handleChange(course);
    navigate("/" + newChelem._id);
  };

  const handlePos = (joueur: "j1" | "j2", pos: number) => {
    if (!pos) pos = 0;
    if (result[joueur] === pos) {
      return;
    }
    const newResult = { ...result };
    newResult[joueur] = pos;
    setResult(newResult);
  };

  return (
    <ResultPage
      icon={
        <>
          <Image
            src={"/img/" + race.img}
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />
        </>
      }
      title={courseName}
      description={formatDate(result?.date)}
      primaryButtonText="save"
      secondaryButtonText="back"
      style={{ background: "url(/assets/checkerboard-black.jpg)" }}
      onSecondaryButtonClick={() => navigate("/" + chelemId)}
      onPrimaryButtonClick={() => handleSave()}
    >
      <ResultPage.Card
        style={{
          padding: 16,
          background: 'url("/assets/checkerboard-white.jpg")',
          borderRadius: "16px",
          margin: 8,
        }}
      >
        <Space direction="vertical" align="center" justify="center">
          <Selector
            columns={8}
            options={[1, 2, 3, 4, 5, 6, 7, 8].map((i) => ({
              label: i,
              value: i,
            }))}
            showCheckMark={false}
            defaultValue={[0]}
            value={[result.j1]}
            onChange={(arr, extend) => handlePos("j1", arr[0])}
          />
          <Selector
            showCheckMark={false}
            columns={8}
            options={[1, 2, 3, 4, 5, 6, 7, 8].map((i) => ({
              label: i,
              value: i,
            }))}
            defaultValue={[0]}
            value={[result.j2]}
            onChange={(arr, extend) => handlePos("j2", arr[0])}
          />
        </Space>
      </ResultPage.Card>

      <Grid columns={2} gap={2}>
        <Grid.Item
          style={{
            padding: 8,
            background: 'url("/assets/checkerboard-white.jpg")',
            borderRadius: "16px",
            margin: 8,
          }}
        >
          <AutoCenter>
            <Space direction="vertical" align="center" justify="center">
              <Image
                src="/assets/th_star.png"
                width={50}
                onClick={() =>
                  setResult({ ...result, stars: result.stars + 1 })
                }
              />
              <Stepper
                style={{ width: "120px" }}
                defaultValue={0}
                step={1}
                value={result.stars}
                onChange={(value) => setResult({ ...result, stars: value })}
              />
            </Space>
          </AutoCenter>
        </Grid.Item>
        <Grid.Item
          style={{
            padding: 8,
            background: 'url("/assets/checkerboard-white.jpg")',
            borderRadius: "16px",
            marginTop: 8,
            margin: 8,
          }}
        >
          <AutoCenter>
            <Space direction="vertical" align="center" justify="center">
              <Image
                src="/assets/th_ghost.png"
                width={50}
                onClick={() =>
                  setResult({ ...result, bigGuyIsLast: !result.bigGuyIsLast })
                }
              />
              <Switch
                checked={result.bigGuyIsLast}
                onChange={() =>
                  setResult({ ...result, bigGuyIsLast: !result.bigGuyIsLast })
                }
                checkedText={<Image src="/assets/th_ghost.png" width={50} />}
                uncheckedText={<Image src="/assets/th_ghost.png" width={20} />}
              />
            </Space>
          </AutoCenter>
        </Grid.Item>
      </Grid>

      <ResultPage.Card
        style={{
          padding: 16,
          background: 'url("/assets/checkerboard-white.jpg")',
          borderRadius: "16px",
          margin: 8,
        }}
      >
        <Space direction="horizontal" align="center" justify="start">
          <ClockCircleFill /> Chrono:
          <Input
            type="number"
            max={59}
            min={0}
            style={{ width: 20, borderBottom: "1px solid black" }}
            placeholder="00"
            value={`0${chrono.mn}`.slice(-2)}
            onChange={(value) => {
              setChrono({ ...chrono, mn: Number(value) });
            }}
          />
          :
          <Input
            type="number"
            max={59}
            min={0}
            style={{ width: 20, borderBottom: "1px solid black" }}
            placeholder="00"
            value={`0${chrono.s}`.slice(-2)}
            onChange={(value) => {
              setChrono({ ...chrono, s: Number(value) });
            }}
          />
          .
          <Input
            type="number"
            max={999}
            min={0}
            style={{ width: 30, borderBottom: "1px solid black" }}
            placeholder="000"
            value={`00${chrono.ms}`.slice(-3)}
            onChange={(value) => {
              setChrono({ ...chrono, ms: Number(value) });
            }}
          />
        </Space>
      </ResultPage.Card>
      <BestTime stats={stats} />
    </ResultPage>
  );
};
